const DOMAINS = {
  DEV: 'ww-frontend.tableofvisions.com',
  STAGE: 'stage-beta.wirwunder.de',
  PROD: 'www.wirwunder.de'
};

const servers = {
  DEV: {
    url: `http://${DOMAINS.DEV}`,
    cors_domain: '*',
    betterplaceUrl: 'https://www.bp42.com/de',
    urlTCWrapper: 'http://cdn.tagcommander.com/2792/uat/tc_wirwunder.js',
    API_URL: {
      url: 'https://ww-api.tableofvisions.com',
      token: 'f725f747-3a65-49f6-a231-3e8944ce464d'
    },
    BACKOFFICE_API_URL: {
      url: 'https://ww-backend.tableofvisions.com',
      token: 's8289793-3a65-49f6-a231-example123',
      internal_url: 'http://wirwunder-backoffice-backoffice-http'
    }
  },
  STAGE: {
    url: `http://${DOMAINS.STAGE}`,
    cors_domain: DOMAINS.STAGE,
    betterplaceUrl: 'https://www.bp42.com/de',
    urlTCWrapper: 'https://cdn.tagcommander.com/2792/uat/tc_wirwunder.js',
    API_URL: {
      url: 'https://stage-api.wirwunder.de',
      token: 'f725f747-3a65-49f6-a231-3e8944ce464d',
      internal_url: 'http://wirwunder-apidb-apidb-http'
    },
    BACKOFFICE_API_URL: {
      url: 'https://stage-admin-bereich.wirwunder.de',
      token: 's8289793-3a65-49f6-a231-example123',
      internal_url: 'http://wirwunder-backoffice-backoffice-http'
    }
  },
  PROD: {
    url: `http://${DOMAINS.PROD}`,
    cors_domain: DOMAINS.PROD,
    betterplaceUrl: 'https://www.betterplace.org/de',
    urlTCWrapper: 'https://cdn.tagcommander.com/2792/tc_wirwunder.js',
    API_URL: {
      url: 'https://api.wirwunder.de',
      token: 'za3f7av2-1bd1-h3a6-b2as-15fsc4a2451q',
      internal_url: 'http://wirwunder-apidb-apidb-http'
    },
    BACKOFFICE_API_URL: {
      url: 'https://admin-bereich.wirwunder.de',
      token: '5vr62sfg-15ee-22ee-2klo-j7el90eklow2',
      internal_url: 'http://wirwunder-backoffice-backoffice-http'
    }
  }
};

const getServerByHost = (hostname) => {
  const domain = Object.entries(DOMAINS).filter((i) => i[1] === hostname)[0];
  const settings = domain ? servers[domain[0]] : servers.DEV;

  return settings;
};

const getServerByEnv = (envName) => {
  const domain = Object.entries(DOMAINS).filter(
    (i) => String(i[0]) === envName
  )[0];
  const settings = domain ? servers[domain[0]] : servers.DEV;

  return settings;
};

exports.isProduction = () => process.env.NODE_ENV === 'production';
exports.buildServers = () => servers;
exports.getServerByHost = getServerByHost;
exports.getServerByEnv = getServerByEnv;
