import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[TC_Wrapper]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	TCWRAPPER: `${prefix} wrapper`
};

const actions = {
	...TYPES,
	setTCWrapper: makeActionCreator(TYPES.TCWRAPPER, 'wrapper')
};

export default actions;
export { actions as setTCWrapperAction };
