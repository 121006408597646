import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	wrapper: null
});

export default function tcwrapperReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.TCWRAPPER: {
			return Immutable.set(state, 'wrapper', payload.wrapper);
		}
		default: {
			return state;
		}
	}
}
