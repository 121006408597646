import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
// eslint-disable-next-line camelcase
import TC_Wrapper from 'react-tag-commander';
import { useIdleTimer } from 'react-idle-timer';
import $ from 'jquery';
import { Helmet } from 'react-helmet';

import 'react-notifications/lib/notifications.css';

import { NotificationContainer } from 'react-notifications';
import { appActions } from '../../redux/app/actions';
// import { partnersAction } from '../../redux/partners/actions';
import { branchesAction } from '../../redux/branches/actions';
import { authActions } from '../../redux/auth/actions';
// import { staticPagesAction } from '../../redux/staticpages/actions';
import { selectBranches } from '../../redux/branches/selectors';
import { selectUI } from '../../redux/app/selectors';
import { selectIsLoggedIn } from '../../redux/auth/selectors';

import { EventProvider } from '../../lib/event-bus';
import { PublicRoutes } from '../../routes';
import { AppLoader } from '../../components/loaders';
import { Root } from '../Root';
import { NotificationProvider } from '../NotificationProvider';

import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorBoundary from '../../components/error-boundry/ErrorBoundary';
import { TCWrapperProvider } from './TCWrapperContext';
import { IE } from './IE';
import { reactIdleTimer } from '../../constants/reactIdleTimer';
import { settings } from '../../services/agent';

let wrapper;

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const App = () => {
  const timeout = reactIdleTimer;
  const [isIdle, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const { reset } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  // const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  const isIE11 = false;
  const { loading } = useSelector(selectUI);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && isIdle) {
      reset();
      dispatch(authActions.logout());
    }
  }, [isLoggedIn, dispatch, isIdle, authActions]);

  useEffect(() => {
    wrapper = TC_Wrapper.getInstance();
    wrapper.addContainer('46', settings.urlTCWrapper, 'head');
  }, []);

  useMount(() => {
    dispatch(appActions.appStart());
    dispatch(branchesAction.loadBranches());
  });

  // MAIN DATA FOR APP BUILDING!!!
  const branches = useSelector(selectBranches);
  if (!branches) {
    return (
      <div className='d-flex justify-content-center align-items-center min-vh-100'>
        <Spinner animation='border' style={{ color: '#ff0000' }} />
        <span className='ml-2'>Seite wird geladen...</span>
      </div>
    );
  }

  return (
    <>

      {isIE11 ? (
        <IE />
      ) : (
          <TCWrapperProvider value={wrapper}>
            <EventProvider>
              <ErrorBoundary>
                <Helmet>
                  <meta itemProp='image' content={`${document.location.origin}/static/img/ww_logo.png`} property='og:image' />
                  <link rel='icon' href='favicon.ico' type='image/x-icon' />
                  <link rel='icon' href='favicon-16-16.png' type='image/png' sizes='16x16' />
                  <link rel='icon' href='favicon-32-32.png' type='image/png' sizes='32x32' />
                </Helmet>
                {isLoggedIn && <Root />}
                {!isLoggedIn && <PublicRoutes />}

                <NotificationProvider />
                <NotificationContainer />
                <AppLoader visible={loading} />
              </ErrorBoundary>
            </EventProvider>
          </TCWrapperProvider>
        )}
    </>
  );
};

export default App;
export { App };
