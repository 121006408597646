/**
 * Adhere alphabetical order for import sections and 'reducers' object.
 */

import App from './app/reducer';
import Auth from './auth/reducer';
import htAccess from './basicauth/reducer';
import Partners from './partners/reducer';
import Kpi from './kpi/reducer';
import Projects from './projects/reducer';
import Branches from './branches/reducer';
import Page4 from './page4/reducer';
import Buttons from './buttons/reducer';
import TCWrapper from './tc/reducer';
import BranchNews from './branchnews/reducer';
import StaticPages from './staticpages/reducer';
import SettingsZweckertrage from './settingsZweckertrage/reducer';
import SettingsStiftung from './settingsStiftung/reducer';
import Faq from './faq/reducer';
import Contacts from './contacts/reducer';
import Supported from './projects-supported/reducer';
import BranchHeaderImage from './branch-header-image/reducer';
import InitialRedirect from './initial-redirect/reducer';
import Applications from './onlineForms/reducer';
import RequestRelease from './requestRelease/reducer';

const reducers = {
  App,
  Auth,
  htAccess,
  Partners,
  Kpi,
  Projects,
  Branches,
  Page4,
  Buttons,
  TCWrapper,
  BranchNews,
  Faq,
  StaticPages,
  PsSettings: SettingsZweckertrage,
  StSettings: SettingsStiftung,
  Contacts,
  Supported,
  BranchHeaderImage,
  InitialRedirect,
  Applications,
  RequestRelease
};

export default reducers;
